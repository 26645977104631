.f-c-fs-c {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.f-c-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.f-r-fs-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.f-r-c-fs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.f-r-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.calendar-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
}

.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 480px;
}

@media (min-width: 760px) {
  .calendar-div {
    width: 760px;
    flex-direction: row;
  }
}