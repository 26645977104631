.tasks-root {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.tasks-form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 350px;
    justify-content: flex-start;
    margin-right: 25px;
}

.tasks-form {
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.tasks-form div {
    width: 100%;
}

.tasks-list {
    position: relative;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tasks-list .paper {
    position: relative;
    width: 100%;
    height: 65px;
    padding: 6px 10px;
    margin-bottom: 16px;
}

.task-description {
    position: absolute;
    top: 6px;
    left: 10px;
}

.created-by {
    position: absolute;
    bottom: 6px;
    left: 10px;
}

.created-at {
    position: absolute;
    bottom: 6px;
    right: 10px;
}

.delete-button {
    position: absolute;
    right: 0;
    top: 1px;
}